import {booleanAttribute, Component, EventEmitter, Input, OnInit, Output, SimpleChanges,} from "@angular/core";
import {AuthedUserService} from "../service/authed-user.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatDividerModule} from "@angular/material/divider";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {GamePlayerResponse} from "../model/responses";
import {AvatarComponent} from "../user/avatar.component";
import {SharedDialogsService} from "../service/shared-dialogs.service";
import {NgxColorsModule} from "ngx-colors";
import {playerColors} from "../model/color-utils";

@Component({
    selector: 'cb-players-data-form',
    template: `
        <mat-accordion>
            <mat-expansion-panel *ngFor="let player of displayedPlayers; let i = index" [expanded]="i == 0 && false">
                <mat-expansion-panel-header>
                    <div class="row space-between align-center fillWidth">
                        <div class="row left align-center">
                            <div class="avatar-container row center align-center"
                                 [style.background-color]="player.color || 'var(--mat-app-background-color)'"
                                 [style.margin-top]="player.isWinner ? '6px' : '0'">
                                <cb-avatar [name]="player.name" [avatarUrl]="player.avatarUrl" [isBot]="player.isBot" [size]="34"></cb-avatar>
                                <img *ngIf="player.isWinner && !starting" class="crown" src="assets/crown.svg" alt="crown"/>
                            </div>
                            <div class="player-text"
                                 [style.max-width]="'calc(100vw - ' + (64 + 48 + 48 + 32 + (player.score ? 30 : 0)) + 'px)'">
                                <span class="team-name" *ngIf="player.players" i18n>[TEAM]</span>
                                <span class="player-name">{{ player.name }}</span>
                                <span class="player-username" *ngIf="player.username">&#64;{{ player.username }}</span>
                                <!--<span class="player-username" *ngIf="!player.username" i18n>(Non-user player)</span>-->
                                <!--<span class="player-username" *ngIf="!player.username" i18n>(Non-user player)</span>-->
                            </div>
                        </div>
                        <span *ngIf="!starting" class="player-score">{{ player.score }}</span>
                    </div>
                </mat-expansion-panel-header>
                <div class="row space-between align-center" style="width: 100%" *ngIf="!starting">
                    <mat-form-field style="padding-right: 20px" subscriptSizing="dynamic">
                        <mat-label i18n>Score</mat-label>
                        <input matInput type="number" [(ngModel)]="player.score" [ngModelOptions]="{standalone: true}"
                               (ngModelChange)="updateData()">
                    </mat-form-field>
                    <mat-slide-toggle [checked]="player.isWinner" (change)="setWinner(player, $event.checked)"
                                      i18n>Winner</mat-slide-toggle>
                </div>
                <div class="row space-between align-center" style="width: 100%" *ngIf="!player.players">
                    <ngx-colors ngx-colors-trigger
                                [palette]="playerColors"
                                overlayClassName="color-picker-overlay"
                                colorPickerControls="no-alpha"
                                [hideTextInput]="true"
                                [(ngModel)]="player.color"
                                (ngModelChange)="updateData()"
                    ></ngx-colors>
                    <mat-form-field subscriptSizing="dynamic">
                        <mat-label i18n>Role</mat-label>
                        <input matInput type="text" [(ngModel)]="player.role" [ngModelOptions]="{standalone: true}"
                               (ngModelChange)="updateData()">
                    </mat-form-field>
                </div>
                <div class="row space-between align-center" style="width: 100%; margin: 8px 0 16px"
                     *ngIf="player.userPlayerId">
                    <button mat-flat-button color="primary" style="width: 100%" (click)="editUserPlayer(player)"
                            i18n>Edit</button>
                </div>
                <mat-accordion *ngIf="player.players">
                    <mat-expansion-panel *ngFor="let teamPlayer of player.players">
                        <mat-expansion-panel-header>
                            <div class="row space-between align-center fillWidth">
                                <div class="row left align-center">
                                    <div class="avatar-container row center align-center"
                                         [style.background-color]="teamPlayer.color || 'var(--mat-app-background-color)'">
                                        <cb-avatar [name]="teamPlayer.name" [avatarUrl]="teamPlayer.avatarUrl" [isBot]="teamPlayer.isBot" [size]="34"></cb-avatar>
                                        <img *ngIf="teamPlayer.isWinner" class="crown" src="assets/crown.svg" alt="crown"/>
                                    </div>
                                    <div class="player-text"
                                         [style.max-width]="'calc(100vw - ' + (64 + 48 + 48 + 32 + 32) + 'px)'">
                                        <span class="player-name">{{ teamPlayer.name }}</span>
                                        <span class="player-username" *ngIf="teamPlayer.username">&#64;{{ teamPlayer.username }}</span>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel-header>
                        <div class="row end align-center" style="width: 100%">
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-label i18n>Role</mat-label>
                                <input matInput type="text" [(ngModel)]="teamPlayer.color" [ngModelOptions]="{standalone: true}"
                                       (ngModelChange)="updateData()">
                            </mat-form-field>
                        </div>
                        <div class="row space-between align-center" style="width: 100%; margin-bottom: 16px"
                             *ngIf="teamPlayer.userPlayerId">
                            <button mat-flat-button color="primary" style="width: 100%" (click)="editUserPlayer(teamPlayer)"
                                    i18n>Edit</button>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-expansion-panel>
        </mat-accordion>
    `,
    styles: [`
        .winner-indicator {
            width: 30px;
        }

        .player-text {
            margin-left: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .player-name {
                font-size: 1rem;
                font-weight: 700;
            }

            .player-username {
                margin-left: 6px;
                font-size: 1rem;
                font-weight: 400;
                color: var(--secondary-text-color);
                min-width: 70px;
            }

            .team-name {
                margin-right: 6px;
                font-size: 0.938rem;
                font-weight: 400;
                color: var(--secondary-text-color);
                min-width: 70px;
            }
        }

        .player-score {
            font-size: 1rem;
            font-weight: 700;
            text-decoration: underline;
            text-align: right;
            margin-right: 8px;
        }

        .avatar-container {
            position: relative;
            padding: 1px;
            border-radius: 50%;
            //background-color: var(--mat-app-background-color);
            z-index: 10;

            .crown {
                position: absolute;
                top: -10px;
                width: 20px;
                height: 20px;
                object-fit: cover;
                z-index: 20;
            }
        }
    `],
    standalone: true,
    imports: [
        CommonModule, ReactiveFormsModule, FormsModule,
        MatFormFieldModule, MatInputModule, MatDatepickerModule, MatExpansionModule, MatIconModule, MatButtonModule, MatSlideToggleModule, MatDividerModule, AvatarComponent, NgxColorsModule
    ]
})
export class PlayersDataFormComponent implements OnInit {

    @Input()
    players: GamePlayerResponse[] = []
    @Output()
    playersChange = new EventEmitter<GamePlayerResponse[]>()

    @Input({transform: booleanAttribute})
    starting: boolean = false

    displayedPlayers: GamePlayerResponse[] = []

    constructor(
        private authedUserService: AuthedUserService,
        private sharedDialogsService: SharedDialogsService,
    ) {
    }

    ngOnInit() {
        this.authedUserService.assertAuthedUser()
        this.updateDisplayedPlayers()
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateDisplayedPlayers()
    }

    updateDisplayedPlayers() {
        //let colorIndex = 0
        //this.players.forEach(p => {
        //    if (!p.color) {
        //        while (this.players.some(p2 => p2.color == playerColors[playerColorPicks[colorIndex]])) {
        //            colorIndex++
        //        }
        //        p.color = playerColors[playerColorPicks[colorIndex++ % playerColorPicks.length]]
        //    }
        //})
        const teamNames = this.players.filter(p => p.team)
            .map(p => p.team!)
            .filter((v, i, a) => a.indexOf(v) === i)
        const teams: GamePlayerResponse[] = teamNames.map(t => {
            const players = this.players.filter(p => p.team == t)
            const player = players[0]
            return {
                name: t,
                isWinner: player.isWinner,
                score: player.score,
                scoreBreakdown: player.scoreBreakdown,
                players: players,
            }
        })
        this.displayedPlayers = this.players.filter(p => !p.team)
        this.displayedPlayers.push(...teams)
    }

    updateTeamPlayers() {
        for (let team of this.displayedPlayers.filter(p => p.players)) {
            for (let teamPlayer of team.players!) {
                teamPlayer.isWinner = team.isWinner
                teamPlayer.score = team.score
                teamPlayer.scoreBreakdown = team.scoreBreakdown
            }
        }
    }

    setWinner(player: GamePlayerResponse, value: boolean) {
        player.isWinner = value
        this.updateData()
    }

    updateData() {
        this.updateTeamPlayers()
        this.playersChange.emit(this.players)
    }

    editUserPlayer(player: GamePlayerResponse) {
        if (!player.userPlayerId) {
            return
        }
        this.sharedDialogsService.editUserPlayer(player).subscribe(player => {
            if (player) {
                this.players = this.players.map(p =>
                    p.userPlayerId == player.id ? {...p, ...player} : p
                )
                this.updateDisplayedPlayers()
                this.updateData()
            }
        })
    }

    protected readonly playerColors = playerColors;
}
