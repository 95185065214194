import {booleanAttribute, Component, EventEmitter, Input, numberAttribute, Output} from '@angular/core';
import {ImageUploadComponent, preview} from "../shared/image-upload.component";
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {getAvatarColor} from "../model/color-utils";

@Component({
    selector: 'cb-avatar',
    template: `
        <div class="avatar-container"
             [style.width.px]="size" [style.height.px]="size"
             [style.min-width.px]="size" [style.min-height.px]="size"
             [style.max-width.px]="size" [style.max-height.px]="size"
        >
            <div class="avatar non-avatar-image"
                 [style.background]="getAvatarColor(name)"
                 [style.font-size.px]="size * 0.55"
                 [style.line-height.px]="size"
                 *ngIf="!isBot && !isAnonymous && !avatarUrl && !avatarFile">
                {{ name.slice(0, 2) }}
            </div>
            <div class="avatar bot-image"
                 [style.font-size.px]="size * 0.4"
                 [style.line-height.px]="size"
                 *ngIf="isBot && !isAnonymous && !avatarUrl && !avatarFile">
                BOT
            </div>
            <img class="avatar" *ngIf="isAnonymous && !avatarUrl && !avatarFile"
                 src="https://img.ludoya.com/assets/meeple/meeple_1.svg" alt="anon-avatar">
            <img class="avatar" *ngIf="avatarUrl && !avatarFile" src="{{avatarUrl}}" alt="avatar">
            <img id="avatar-preview" class="avatar" *ngIf="avatarFile" i18n-alt alt="Avatar">
            <cb-image-upload *ngIf="editable" (onFileSelected)="selectAvatar($event)">
                <div class="avatar-edit-button column center align-center">
                    <mat-icon>add_a_photo</mat-icon>
                </div>
            </cb-image-upload>
        </div>
    `,
    styles: [`
        .avatar-container {
            position: relative;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            .avatar {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .non-avatar-image {
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                color: white;
                text-shadow:
                    -1px -1px 0 #00000020,
                    1px -1px 0 #00000020,
                    -1px 1px 0 #00000020,
                    1px 1px 0 #00000020,
                    -2px -2px 0 #00000020,
                    2px -2px 0 #00000020,
                    -2px 2px 0 #00000020,
                    2px 2px 0 #00000020;
            }

            .bot-image {
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                color: black;
                background: linear-gradient(135deg, #BBBBBB, #555555);
                text-shadow:
                    -1px -1px 0 #FFFFFF20,
                    1px -1px 0 #FFFFFF20,
                    -1px 1px 0 #FFFFFF20,
                    1px 1px 0 #FFFFFF20,
                    -2px -2px 0 #FFFFFF20,
                    2px -2px 0 #FFFFFF20,
                    -2px 2px 0 #FFFFFF20,
                    2px 2px 0 #FFFFFF20;
            }

            .avatar-edit-button {
                position: relative;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: rgba(0, 0, 0, 0.5);
                cursor: pointer;
                z-index: 1;
            }
        }

        .edit-profile-form {
            width: calc(100% - 40px);
            margin: 8px 16px 16px;
        }
    `],
    standalone: true,
    imports: [CommonModule, ImageUploadComponent, MatIconModule, MatButtonModule]
})
export class AvatarComponent {

    @Input({transform: numberAttribute})
    size: number = 100
    @Input()
    avatarUrl?: string
    @Input()
    name: string
    @Input({transform: booleanAttribute})
    isBot?: boolean = false

    @Input({transform: booleanAttribute})
    editable = false
    @Output()
    avatarChange = new EventEmitter<Blob>()

    avatarFile?: Blob

    isAnonymous = false

    ngOnInit() {
        const anonymousName = $localize`Anonymous`
        this.isAnonymous = this.name.length == anonymousName.length + 2 && this.name.startsWith(anonymousName + ' ')
    }

    selectAvatar(file: Blob) {
        this.avatarFile = file
        this.avatarChange.emit(file)
        preview(file, 'avatar-preview')
    }

    protected readonly getAvatarColor = getAvatarColor;
}
