import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {isIosWebView} from "./util";

@Injectable()
export class GeoLocationService {

    getGeoLocation(): Observable<GeolocationPosition> {
        return new Observable((observer) => {
            if (isIosWebView()) {
                observer.error('Geolocation is not supported in iOS WebView.');
                return;
            }

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position: GeolocationPosition) => {
                        observer.next(position);
                        observer.complete();
                    },
                    (error: GeolocationPositionError) => {
                        observer.error(error);
                    }
                );
            } else {
                observer.error('Geolocation is not supported by this browser.');
            }
        });
    }
}
