import {Component, Inject} from "@angular/core";
import {LocationResponse} from "../model/responses";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {LocationListComponent} from "./location-list.component";
import {Visibility} from "../model/visibility";

@Component({
    template: `
    <h1 mat-dialog-title i18n>Locations</h1>
    <div mat-dialog-content>
        <cb-location-list
            [(locations)]="data.locations" [canSelect]="data.canSelect" (onLocationSelected)="close($event)"
            [defaultVisibility]="data.defaultVisibility">
        </cb-location-list>
    </div>
    <div mat-dialog-actions class="row start">
      <button mat-button (click)="close()" i18n>Close</button>
    </div>
  `,
    styles: [`

        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
  `],
    standalone: true,
    imports: [MatDialogModule, LocationListComponent, MatButtonModule]
})
export class LocationListDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<LocationListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {locations: LocationResponse[], canSelect: boolean, defaultVisibility?: Visibility}
  ) {
  }

  close(selectedLocation?: LocationResponse) {
    this.dialogRef.close(selectedLocation)
  }
}
