import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {GamePlayTemplateResponse, Page,} from "../../model/responses";
import {environment} from "../../../environments/environment";
import {GamePlayTemplateRequest, GamePlayTemplateUpdateRequest,} from "../../model/requests";

@Injectable()
export class GamePlayTemplateService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getGamePlayTemplates(boardGameId: string, pageSize: number, pageIndex: number): Observable<Page<GamePlayTemplateResponse>> {
    const params = {boardGameId: boardGameId, pagination: pageSize + ',' + pageIndex}
    return this.http.get<Page<GamePlayTemplateResponse>>(`${environment.apiUrl}/gameplay-templates`, { params: params });
  }

  getGamePlayTemplate(id: string): Observable<GamePlayTemplateResponse> {
    return this.http.get<GamePlayTemplateResponse>(`${environment.apiUrl}/gameplay-templates/${id}`);
  }

  createGamePlayTemplate(request: GamePlayTemplateRequest): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/gameplay-templates`, request);
  }

  updateGamePlayTemplate(templateId: string, request: GamePlayTemplateUpdateRequest): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/gameplay-templates/${templateId}`, request);
  }

  deleteGamePlayTemplate(templateId: string): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/gameplay-templates/${templateId}`);
  }
}
