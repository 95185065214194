import {booleanAttribute, Component, EventEmitter, Input, OnChanges, OnInit, Output} from "@angular/core";
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {compressImage} from "../shared/image-upload.component";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {AttachImagePreviewComponent} from "./attach-image-preview.component";
import {CommonModule} from "@angular/common";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";

@Component({
    selector: 'cb-write-post-form',
    template: `
    <mat-form-field appearance="fill" subscriptSizing="dynamic">
      <mat-label i18n>Write post</mat-label>
      <textarea matInput cdkTextareaAutosize [formControl]="postContentForm" rows="3"></textarea>
      <mat-error *ngIf="postContentForm.hasError('required')" i18n>
        Post content is required
      </mat-error>
    </mat-form-field>

    <div class="write-post-attachments-display column space-around align-center" *ngIf="files.length > 0">
      <div class="row space-around align-center">
        <cb-attach-image-preview *ngIf="files.length > 0" previewId="form-preview-1" [file]="files[0]"
                                  (onDeleted)="removeFile(0)"></cb-attach-image-preview>
        <cb-attach-image-preview *ngIf="files.length > 1" previewId="form-preview-2" [file]="files[1]"
                                  (onDeleted)="removeFile(1)"></cb-attach-image-preview>
      </div>
      <div class="row space-around align-center">
        <cb-attach-image-preview *ngIf="files.length > 2" previewId="form-preview-3" [file]="files[2]"
                                  (onDeleted)="removeFile(2)"></cb-attach-image-preview>
        <cb-attach-image-preview *ngIf="files.length > 3" previewId="form-preview-4" [file]="files[3]"
                                  (onDeleted)="removeFile(3)"></cb-attach-image-preview>
      </div>
    </div>

    <div *ngIf="files.length < 4" class="write-post-attachment-buttons row space-between">
      <div></div>
      <button mat-icon-button (click)="fileInput.click()" style="color: white">
        <mat-icon>add_photo_alternate</mat-icon>
      </button>
      <input #fileInput hidden type="file" id="file-input" accept="image/*" (change)="addFile($event)">
    </div>
  `,
    styles: [`
    .mat-mdc-form-field {
      width: 100%;
    }

    .write-post-attachments-display {
      //margin-top: -10px;
      overflow: hidden;
    }

    .write-post-attachment-buttons {
      margin-top: 10px;
      margin-right: 10px;
    }
  `],
    standalone: true,
    imports: [
        CommonModule, ReactiveFormsModule,
        MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule,
        AttachImagePreviewComponent
    ]
})
export class WritePostFormComponent implements OnInit, OnChanges {

  @Input({transform: booleanAttribute})
  postContentRequired = false
  @Input()
  postContent = ''
  @Input()
  files: Blob[] = []

  postContentForm = new FormControl(this.postContent, this.postContentRequired ? Validators.required : null)

  @Output()
  postContentChange = new EventEmitter<string>()
  @Output()
  filesChange = new EventEmitter<Blob[]>()

  ngOnInit() {
    this.postContentForm.setValue(this.postContent)
    this.postContentForm.valueChanges.subscribe(value => {
      this.postContent = value || ''
      this.postContentChange.emit(value || '')
    })
  }

  ngOnChanges() {
    this.postContentForm.setValue(this.postContent)
  }

  addFile(event: Event) {
    const file = (event.target as HTMLInputElement)!.files![0]
    compressImage(file).then(compressedFile => {
      this.files.push(compressedFile)
      this.filesChange.emit(this.files)
    })
  }

  removeFile(index: number) {
    this.files.splice(index, 1)
    this.filesChange.emit(this.files)
  }
}
