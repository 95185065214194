import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {UserFavCountsResponse, UserFavsResponse} from "../../model/responses";
import {environment} from "../../../environments/environment";

@Injectable()
export class UserFavService {

  constructor(
    private http: HttpClient,
  ) {
  }

  addFav(username: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/favs`, { username: username })
  }

  deleteFav(id: string): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/favs/${id}`);
  }

  getUserFavCounts(userId: string): Observable<UserFavCountsResponse> {
    return this.http.get<UserFavCountsResponse>(`${environment.apiUrl}/user/${userId}/favs/count`);
  }

  getUserFavs(userId: string): Observable<UserFavsResponse> {
    return this.http.get<UserFavsResponse>(`${environment.apiUrl}/user/${userId}/favs`);
  }
}
