import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {LocationResponse} from "../model/responses";
import {AuthedUserService} from "../service/authed-user.service";
import {Visibility} from "../model/visibility";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {BreakpointObserver} from "@angular/cdk/layout";
import {ConfirmDialogComponent} from "../shared/confirm-dialog.component";
import {EditLocationDialogComponent} from "./edit-location-dialog.component";
import {LocationService} from "../service/api/location.service";
import {locationVisibilityTexts} from "./location-visibility-select.component";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatExpansionModule} from "@angular/material/expansion";
import {CommonModule} from "@angular/common";
import {DialogService} from "../service/ui/dialog.service";
import {FormsModule} from "@angular/forms";
import {MatSlideToggle} from "@angular/material/slide-toggle";

@Component({
    selector: 'cb-location-list',
    template: `
        <div class="column" style="margin: 16px 0">
            <div class="column" *ngIf="locations && locations.length > 0">
                <div class="row end" style="margin-bottom: 8px">
                    <mat-slide-toggle [(ngModel)]="editMode" style="margin-bottom: 8px" i18n>Manage Locations</mat-slide-toggle>
                </div>
                <ng-container *ngFor="let location of locations">
                    <div class="row space-between align-center"
                         style="margin-bottom: 8px; color: var(--mat-app-text-color); background-color: var(--mat-app-background-color-variant); padding: 8px"
                         [class.clickable]="canSelect && !editMode"
                         (click)="canSelect && !editMode ? onLocationSelected.emit(location) : undefined">
                        <div class="column align-start">
                            <div class="row start align-center">
                                <mat-icon *ngIf="location.isDefault">star</mat-icon>
                                <mat-icon>{{ locationVisibilityTexts[location.visibility].icon }}</mat-icon>
                                <span style="margin-left: 8px">{{location.name}}</span>
                            </div>
                            <span *ngIf="location.address" style="color:var(--secondary-text-color); margin-top: 4px">{{location.address}}</span>
                        </div>
                        <div class="row" *ngIf="editMode">
                            <button mat-icon-button class="small-icon-button" (click)="openEditLocationDialog(location)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button color="warn" class="small-icon-button" (click)="openDeleteDialog(location)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="no-content-message-container column center align-center"
                 *ngIf="locations && !locations.length">
                <span class="no-content-message" i18n>No locations</span>
            </div>
            <div class="row end">
                <button mat-stroked-button class="add-button fill" (click)="openNewLocationDialog()">
                    <mat-icon>add</mat-icon>
                    <span i18n>New Location</span>
                </button>
            </div>
        </div>
    `,
    styles: [`
        .no-content-message-container {
            margin: 32px 16px 16px;

            .no-content-message {
                font-size: 1.6rem;
                font-weight: 700;
                text-align: center;
                color: var(--secondary-text-color);
            }
        }
    `],
    standalone: true,
    imports: [
        CommonModule, FormsModule,
        MatExpansionModule, MatButtonModule, MatIconModule, MatSlideToggle,
    ]
})
export class LocationListComponent implements OnInit {

    @Input()
    locations?: LocationResponse[]
    @Input()
    canSelect = false
    @Input()
    defaultVisibility?: Visibility

    @Output()
    locationsChange = new EventEmitter<LocationResponse[]>()
    @Output()
    onLocationSelected = new EventEmitter<LocationResponse>()

    // Component state
    editMode = true

    constructor(
        private locationService: LocationService,
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        private authedUserService: AuthedUserService,
        private dialogService: DialogService,
    ) {
        this.authedUserService.assertAuthedUser()
    }

    ngOnInit() {
        if (!this.locations) {
            this.loadLocations()
        }
        this.editMode = !this.canSelect
    }

    loadLocations() {
        this.locationService.getLocations().subscribe(locations => {
            this.locations = locations.list
            this.locationsChange.emit(this.locations)
        })
    }

    openNewLocationDialog() {
        let dialogConfig: MatDialogConfig = {
            data: {
                defaultVisibility: this.defaultVisibility,
            }
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(EditLocationDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        dialog.afterClosed().subscribe(template => {
            if (template) {
                this.loadLocations()
            }
        })
    }

    openEditLocationDialog(location?: LocationResponse) {
        let dialogConfig: MatDialogConfig = {
            data: {
                location: location,
            }
        }
        if (this.breakpointObserver.isMatched('(max-width: 500px)')) {
            dialogConfig = {
                ...dialogConfig,
                height: '100%',
                width: '100%',
                maxWidth: '100%'
            }
        }
        const dialog = this.dialog.open(EditLocationDialogComponent, dialogConfig)
        dialog.afterClosed().subscribe(template => {
            if (template) {
                this.loadLocations()
            }
        })
    }

    openDeleteDialog(location: LocationResponse) {
        this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: $localize`Delete Location`,
                question: $localize`Are you sure you want to permanently delete this location?`
            }
        }).afterClosed().subscribe(confirmed => {
            if (confirmed) {
                this.locationService.deleteLocation(location.id).subscribe(() => {
                    this.loadLocations()
                });
            }
        })
    }

    protected readonly locationVisibilityTexts = locationVisibilityTexts;
}
