import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule} from "@angular/material/dialog";
import {GamePlayData} from "./log-play-data-form.component";
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CommonModule} from '@angular/common';
import {GamePlayTemplateResponse} from "../model/responses";
import {EditPlayDialogData} from "./edit-play-dialog.component";
import {GameHelperListComponent} from "../game/game-helper-list.component";
import {MatTableModule} from "@angular/material/table";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthedUserService} from "../service/authed-user.service";
import {UserGamePlayAccess} from "../model/user";
import {evaluateExpression} from "../model/math";

@Component({
    template: `
        <h1 mat-dialog-title *ngIf="!selectedTemplate" i18n>Score Sheet</h1>
        <h1 mat-dialog-title *ngIf="selectedTemplate">{{ selectedTemplate.name }}</h1>
        <mat-dialog-content>
            <div *ngIf="access == 'EDIT' || access == 'EDIT_SELF'">
                <div *ngIf="dialogData.game && !selectedTemplate">
                    <span i18n>Select a score sheet</span>
                    <cb-game-helper-list [game]="dialogData.game" canSelect
                                         (selected)="selectScoreSheet($event)"></cb-game-helper-list>
                </div>
                <div *ngIf="selectedTemplate" class="row space-between align-center">
                    <span i18n>{{selectedTemplate.name}} by {{selectedTemplate.author.name}}</span>
                    <button mat-flat-button color="primary" (click)="selectedTemplate = undefined; selectScoreSheet(undefined)" i18n>Change</button>
                </div>
            </div>
            <div class="column">
                <div style="overflow: auto; margin-top: 8px">
                    <table mat-table [dataSource]="scoreSheet">
                        <ng-container [matColumnDef]="'SHEET'" sticky>
                            <th mat-header-cell *matHeaderCellDef
                                style="background-color: var(--mat-app-background-color)">
                                <mat-slide-toggle [(ngModel)]="calculator" [ngModelOptions]="{standalone: true}" i18n>Calculator in cells</mat-slide-toggle>
                            </th>
                            <td mat-cell *matCellDef="let element"
                                style="background-color: #374d62">{{ element.name }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let player of playData.players"
                                      [matColumnDef]="player.userId || player.userPlayerId || player.name">
                            <th mat-header-cell *matHeaderCellDef
                                style="text-align: center; background-color: #374d62">{{ player.name }}
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 0">
                                <mat-form-field appearance="fill" subscriptSizing="dynamic" style="width: 100%"
                                                *ngIf="!element.winner">
                                    <!--<mat-label>{{element}}</mat-label>-->
                                    <input matInput class="score-input" *ngIf="!element.total"
                                           [type]="calculator ? 'text' : 'number'"
                                           [(ngModel)]="player.scoreBreakdown![element.name]"
                                           [ngModelOptions]="{standalone: true}"
                                           (ngModelChange)="onScoreChange(player.userId || player.userPlayerId || player.name, element, $event)"
                                           style="text-align: center"
                                           (blur)="onScoreFocusOut(player.userId || player.userPlayerId || player.name, element)"
                                           [disabled]="access != 'EDIT' && (access != 'EDIT_SELF' || player.username != currentUsername)"
                                    >
                                    <input matInput class="score-input" *ngIf="element.total"
                                           [type]="calculator ? 'text' : 'number'"
                                           [(ngModel)]="player.score"
                                           [ngModelOptions]="{standalone: true}"
                                           style="text-align: center; font-weight: 700"
                                           (blur)="onScoreFocusOut(player.userId || player.userPlayerId || player.name, element)"
                                           (ngModelChange)="onScoreChange(player.userId || player.userPlayerId || player.name, element, $event)"
                                           [disabled]="access != 'EDIT' && (access != 'EDIT_SELF' || player.username != currentUsername || element.editable != true)"
                                    >
                                </mat-form-field>
                                <div *ngIf="element.winner" class="row center">
                                    <mat-slide-toggle *ngIf="access == 'EDIT'"
                                                      [(ngModel)]="player.isWinner"
                                                      [ngModelOptions]="{standalone: true}"
                                                      (ngModelChange)="setWinner(player.userId || player.userPlayerId || player.name, $event)"
                                    ></mat-slide-toggle>
                                    <mat-icon *ngIf="player.isWinner && access != 'EDIT'" color="primary">
                                        emoji_events
                                    </mat-icon>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="playerIds"></tr>
                        <tr mat-row *matRowDef="let row; columns: playerIds;"></tr>
                    </table>
                </div>
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between" style="margin-top: 16px">
            <button mat-button mat-dialog-close cdkFocusInitial i18n>Back</button>
        </div>
    `,
    styles: [`
        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [
        CommonModule, ReactiveFormsModule, FormsModule,
        MatDialogModule, MatProgressSpinnerModule, MatButtonModule, MatTableModule, MatFormFieldModule, MatInputModule, MatSlideToggleModule, MatIconModule,
        GameHelperListComponent
    ]
})
export class PlayScoreSheetDialogComponent {

    currentUsername?: string

    playerIds: string[] = []

    access: UserGamePlayAccess = UserGamePlayAccess.EDIT
    playData: GamePlayData
    selectedTemplate?: GamePlayTemplateResponse
    calculator = false

    scoreSheet = [
        {name: $localize`Score`, total: true, editable: true, winner: false},
        {name: $localize`Winner`, total: false, editable: true, winner: true},
    ]

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: EditPlayDialogData,
        private authedUserService: AuthedUserService,
    ) {
        this.authedUserService.assertAuthedUser()
        this.currentUsername = this.authedUserService.getUserData()!.username
        this.playData = dialogData.data
        this.selectScoreSheet(this.playData.template)
    }

    selectScoreSheet(template?: GamePlayTemplateResponse) {
        this.playerIds = this.playData.players.map(p => p.userId || p.userPlayerId || p.name)
        this.playerIds.unshift('SHEET')

        if (!template) {
            this.scoreSheet = [
                {name: $localize`Score`, total: true, editable: true, winner: false},
                {name: $localize`Winner`, total: false, editable: true, winner: true},
            ]
            return
        }
        this.selectedTemplate = template
        this.playData.template = template

        if (this.selectedTemplate.data.scoreSheet) {
            this.scoreSheet = this.selectedTemplate.data.scoreSheet.map(s => {
                return {name: s.name, total: false, editable: true, winner: false}
            })
            this.scoreSheet.push({name: $localize`Total`, total: true, editable: false, winner: false})
            this.scoreSheet.push({name: $localize`Winner`, total: false, editable: true, winner: true})
            this.playData.players.forEach(p => {
                if (!p.scoreBreakdown) {
                    p.scoreBreakdown = {}
                }
                //parse p.score to int
                p.loadedScore = p.score
                p.loadedScoreBreakdown = {...p.scoreBreakdown}
            })
        }
    }

    onScoreChange(playerId: string, row: { name: string, total: boolean }, score?: string) {
        const affectedPlayer = this.playData!.players.find(p => p.userId && p.userId == playerId) ||
            this.playData!.players.find(p => p.userPlayerId == playerId) ||
            this.playData!.players.find(p => p.name == playerId)
        if (!affectedPlayer) {
            return
        }

        const calc = evaluateExpression(score)
        //console.log('onScoreChange', playerId, row, score, calc)
        if (!affectedPlayer.updatingScore) {
            affectedPlayer.updatingScore = true
            affectedPlayer.editedScore = affectedPlayer.score
            affectedPlayer.editedScoreBreakdown = {...affectedPlayer.scoreBreakdown}
        }
        if (row.total) {
            if (calc != undefined) {
                affectedPlayer.editedScore = calc
            } else {
                affectedPlayer.editedScore = undefined
            }
        } else {
            if (calc != undefined) {
                affectedPlayer.editedScoreBreakdown![row.name] = calc
            } else {
                affectedPlayer.editedScoreBreakdown![row.name] = undefined
            }
            affectedPlayer.editedScore = Object.values(affectedPlayer.editedScoreBreakdown!).reduce((a, b) => (a || 0) + (b || 0), 0)
            affectedPlayer.score = affectedPlayer.editedScore
        }

        const topScore = Math.max(...this.playData.players.map(p => p.editedScore || 0))
        this.playData.players.forEach(p => {
            p.isWinner = p.editedScore == topScore
        })
        //if (this.play!.players.length < 2) {
        //    return
        //}

        //this.gamePlayService.updateGamePlayerScore(this.playId, playerId, affectedPlayer.editedScore, affectedPlayer.editedScoreBreakdown).subscribe()
    }

    onScoreFocusOut(playerId: string, row: { name: string, total: boolean }) {
        console.log('onScoreFocusOut', playerId, row)
        const affectedPlayer = this.playData!.players.find(p => p.userId && p.userId == playerId) ||
            this.playData!.players.find(p => p.userPlayerId == playerId)
        console.log(affectedPlayer)
        if (!affectedPlayer) {
            return
        }

        //console.log(affectedPlayer.score)

        affectedPlayer.loadedScore = affectedPlayer.editedScore
        if (!row.total) {
            affectedPlayer.loadedScoreBreakdown![row.name] = affectedPlayer.editedScoreBreakdown![row.name]
        }

        affectedPlayer.score = affectedPlayer.loadedScore
        affectedPlayer.scoreBreakdown = affectedPlayer.loadedScoreBreakdown
        affectedPlayer.loadedScore = undefined
        affectedPlayer.loadedScoreBreakdown = undefined
        affectedPlayer.updatingScore = false
    }

    setWinner(playerId: string, winner: boolean) {
        const affectedPlayer = this.playData!.players
            .find(p => (p.userId && p.userId == playerId) || (p.userPlayerId && p.userPlayerId == playerId))
        if (!affectedPlayer) {
            return
        }

        //console.log('setWinner', playerId, winner)

        affectedPlayer.isWinner = winner

        //this.gamePlayService.updateGamePlayerWinner(this.playId, playerId, affectedPlayer.isWinner).subscribe()
    }
}
