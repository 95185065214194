import {booleanAttribute, Component, EventEmitter, Input, Output} from "@angular/core";
import {BoardGameBasicResponse, GamePlayTemplateResponse,} from "../model/responses";
import {AuthedUserService} from "../service/authed-user.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {GamePlayTemplateService} from "../service/api/game-play-template.service";
import {GameHelperDialogComponent} from "./game-helper-dialog.component";
import {ConfirmDialogComponent} from "../shared/confirm-dialog.component";
import {getLanguage, Language, languages} from "../model/languages";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatOptionModule} from "@angular/material/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatSelectModule} from "@angular/material/select";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDividerModule} from "@angular/material/divider";
import {NgFor, NgIf} from "@angular/common";
import {DialogService} from "../service/ui/dialog.service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatSlideToggle} from "@angular/material/slide-toggle";

@Component({
    selector: 'cb-game-helper-list',
    template: `
        <div class="column">
            <mat-form-field style="margin: 8px" subscriptSizing="dynamic" *ngIf="templates && templates.length > 0">
                <mat-label i18n>Language</mat-label>
                <mat-select [(ngModel)]="language" (ngModelChange)="filterTemplates()" name="language">
                    <mat-option [value]="undefined" i18n>Any</mat-option>
                    <mat-option *ngFor="let language of languages" [value]="language">{{ language?.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-accordion class="column" style="margin: 8px"
                           *ngIf="filteredTemplates && filteredTemplates.length > 0">
                <mat-expansion-panel *ngFor="let template of filteredTemplates">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ template.name }}</mat-panel-title>
                        <mat-panel-description
                                i18n>By {{ template.author.name }} (&#64;{{ template.author.username }})</mat-panel-description>
                    </mat-expansion-panel-header>
                    <span>{{ template.description }}</span>
                    <li *ngFor="let step of template.data.scoreSheet">
                        <span>{{ step.name }}</span>
                    </li>
                    <mat-action-row class="row space-between align-center" *ngIf="canSelect">
                        <mat-slide-toggle (change)="setDefault(template, $event.checked)"
                                          i18n>Set as default for this game</mat-slide-toggle>
                        <button mat-flat-button color="accent" (click)="selected.emit(template)"
                                i18n>Select</button>
                    </mat-action-row>
                    <mat-action-row>
                        <button mat-button (click)="openTemplateDialog(template, false)" i18n>Copy</button>
                        <button mat-button *ngIf="template.author.username == currentUsername"
                                (click)="openTemplateDialog(template, true)"
                                i18n>Edit</button>
                        <button mat-button color="warn" *ngIf="template.author.username == currentUsername"
                                (click)="openDeleteDialog(template)"
                                i18n>Delete</button>
                    </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>
            <div class="column center align-center flex" *ngIf="loading" style="height: 128px">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
            <div class="no-content-message-container column center align-center"
                 *ngIf="!loading && filteredTemplates && !filteredTemplates.length">
                <span class="no-content-message" i18n>No score sheets for {{ game.name }}</span>
            </div>
            <div class="row end" style="padding: 8px">
                <button *ngIf="currentUsername" mat-stroked-button class="add-button fill"
                        (click)="openTemplateDialog()">
                    <mat-icon>add</mat-icon>
                    <span i18n>Create New Score Sheet</span>
                </button>
            </div>
        </div>
    `,
    styles: [`
        .no-content-message-container {
            margin: 32px 16px 16px;

            .no-content-message {
                font-size: 1.1rem;
                font-weight: 700;
                text-align: center;
                color: var(--secondary-text-color);
            }
        }
    `],
    standalone: true,
    imports: [NgIf, MatDividerModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, FormsModule, MatOptionModule, NgFor, MatExpansionModule, MatButtonModule, MatIconModule, MatProgressSpinner, MatSlideToggle]
})
export class GameHelperListComponent {

    @Input()
    game: BoardGameBasicResponse;

    @Input({transform: booleanAttribute})
    canSelect: boolean = false;

    @Output()
    selected = new EventEmitter<GamePlayTemplateResponse>();

    templates: GamePlayTemplateResponse[] = []
    filteredTemplates: GamePlayTemplateResponse[] = []

    currentUsername?: string

    language?: Language

    loading: boolean = false

    constructor(
        private gamePlayTemplateService: GamePlayTemplateService,
        private localStorageService: AuthedUserService,
        private dialog: MatDialog,
        private authedUserService: AuthedUserService,
        private dialogService: DialogService,
    ) {
        this.authedUserService.assertAuthedUser()
        this.language = getLanguage(this.authedUserService.getAuthedUser()!.userData!.locale) || languages[0]
    }

    ngOnInit() {
        this.currentUsername = this.localStorageService.getUsername()
        this.loadTemplates()
    }

    loadTemplates() {
        this.loading = true
        this.gamePlayTemplateService.getGamePlayTemplates(this.game!.id, 100, 0).subscribe(templates => {
            this.templates = templates.elements
                // For now, only show templates with score sheets
                .filter(template => template.data.scoreSheet && template.data.scoreSheet.length > 0)
            this.filterTemplates()
            if (this.filteredTemplates.length == 0) {
                this.language = undefined
                this.filteredTemplates = this.templates
            }
            this.loading = false
        })
    }

    filterTemplates() {
        this.filteredTemplates = this.templates
            ?.filter(template => !this.language || template.language == this.language.code)
    }

    openTemplateDialog(baseTemplate?: GamePlayTemplateResponse, update: boolean = false) {
        let dialogConfig: MatDialogConfig = {
            data: {
                game: this.game,
                baseTemplate: baseTemplate,
                update: update
            },
            width: '70%',
            height: '90%',
            disableClose: true
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(GameHelperDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        dialog.afterClosed().subscribe(template => {
            if (template) {
                this.loadTemplates()
            }
        })
    }

    openDeleteDialog(template: GamePlayTemplateResponse) {
        this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: $localize`Delete Score Sheet`,
                question: $localize`Are you sure you want to permanently delete this score sheet?`
            }
        }).afterClosed().subscribe(confirmed => {
            if (confirmed) {
                this.gamePlayTemplateService.deleteGamePlayTemplate(template.id).subscribe(() => {
                    this.loadTemplates()
                })
            }
        })
    }

    setDefault(template: GamePlayTemplateResponse, checked: boolean) {
        if (checked) {
            localStorage.setItem(`defaultTemplate-${this.game.id}`, template.id)
        } else {
            localStorage.removeItem(`defaultTemplate-${this.game.id}`)
        }
    }

    protected readonly languages = languages;
}
