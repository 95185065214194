import {Component} from '@angular/core';
import {
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from "@angular/material/dialog";
import {environment} from "../../environments/environment";
import {MatButtonModule} from '@angular/material/button';
import {CommonModule} from "@angular/common";
import {LandingService} from "../service/api/landing.service";
import {NewsArticleResponse} from "../model/responses";
import {MarkdownComponent} from "ngx-markdown";

@Component({
    template: `
        <h1 mat-dialog-title i18n>What's new</h1>
        <mat-dialog-content>
            <div class="column">
                <div *ngFor="let article of news" class="article row space-between">
                    <div class="column">
                        <span class="date">{{ article.createdAt | date }}</span>
                        <span class="title">{{ article.title }}</span>
                        <img *ngIf="article.imageUrl"
                                  [src]="article.imageUrl"
                                  style="margin-top: 16px; max-width: calc(min(100%, 500px)); max-height: 500px"
                         alt="new">
                        <markdown [data]="article.markdownContent"></markdown>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between">
            <button mat-button cdkFocusInitial mat-dialog-close i18n>Close</button>
        </div>
    `,
    styles: [`
        .article {
            color: var(--mat-app-text-color);
            font-size: 0.938rem;
            line-height: 1.34;
            margin-bottom: 24px;

            .date {
                color: #c1ccd0;
                font-size: 0.875rem;
                margin-bottom: 4px;
            }

            .title {
                color: #df9a57;
                font-size: 1.1rem;
                line-height: 1.34;
                font-weight: 700;
            }
        }
        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule, MatDialogContent, MatDialogTitle, MatDialogActions, MatDialogClose,
        MarkdownComponent,
    ]
})
export class NewsDialogComponent {

    news: NewsArticleResponse[] = []

    constructor(
        private dialogRef: MatDialogRef<NewsDialogComponent>,
        private landingService: LandingService,
    ) {
        landingService.getNewsCached().subscribe(news => {
            this.news = news.news.elements
        })
    }

    protected readonly environment = environment;
}
