import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {
    BoardGameExpansionsResponse,
    BoardGameResponse,
    BoardGameVersionsResponse,
    ShopItemsResponse
} from "../../model/responses";
import {environment} from "../../../environments/environment";

@Injectable()
export class BoardGameService {

  constructor(
    private http: HttpClient
  ) {
  }

  getGame(slug: string): Observable<BoardGameResponse> {
    return this.http.get<BoardGameResponse>(`${environment.apiUrl}/boardgames/${slug}`);
  }

  getGameExpansions(slug: string): Observable<BoardGameExpansionsResponse> {
    return this.http.get<BoardGameExpansionsResponse>(`${environment.apiUrl}/boardgames/${slug}/expansions`);
  }

  getGameVersions(slug: string): Observable<BoardGameVersionsResponse> {
    return this.http.get<BoardGameVersionsResponse>(`${environment.apiUrl}/boardgames/${slug}/versions`);
  }

  getGamePrices(id: string): Observable<ShopItemsResponse> {
    return this.http.get<ShopItemsResponse>(`${environment.apiUrl}/boardgames/${id}/prices`);
  }
}
