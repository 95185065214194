import {Component, Inject, OnInit} from '@angular/core';
import {GeoLocationService} from "../service/geo-location.service";
import {LocationRequest, UpdateUserGeoLocationRequest} from "../model/requests";
import {UserService} from "../service/api/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {GoogleMapsModule, MapGeocoder} from "@angular/google-maps";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {Visibility} from "../model/visibility";
import {LocationService} from "../service/api/location.service";
import {LocationResponse} from "../model/responses";
import {GoogleMapsService} from "../service/ui/google-maps.service";
import {LocationVisibilitySelectComponent} from './location-visibility-select.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {AsyncPipe, CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatExpansionModule} from "@angular/material/expansion";
import {MatSlideToggle} from "@angular/material/slide-toggle";

@Component({
    template: `
        <h1 mat-dialog-title *ngIf="data.location" i18n>New Location</h1>
        <h1 mat-dialog-title *ngIf="!data.location" i18n>Edit Location</h1>
        <div mat-dialog-content>
            <mat-form-field style="width: 100%">
                <mat-label i18n>Location Name</mat-label>
                <input matInput [(ngModel)]="name" name="name" required>
            </mat-form-field>
            <cb-location-visibility-select [(visibility)]="visibility"></cb-location-visibility-select>
            <mat-expansion-panel [expanded]="visibility == 'PUBLIC'">
                <mat-expansion-panel-header>
                    <mat-panel-title i18n>Address</mat-panel-title>
                    <mat-panel-description i18n>(optional)</mat-panel-description>
                </mat-expansion-panel-header>
                <mat-form-field style="width: 100%">
                    <mat-label i18n>Location Address</mat-label>
                    <input matInput [(ngModel)]="markerAddress" name="address" (keydown.enter)="checkAddress()"
                           (blur)="checkAddress()">
                    <button mat-icon-button matSuffix *ngIf="markerAddress && markerAddress != checkedAddress"
                            (click)="checkAddress()">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
                <span class="section-title" i18n>Pick a spot in the map</span>
                <google-map *ngIf="mapCenter && (googleMapsService.getApiLoadStatus() | async)"
                            width="100%" height="300px"
                            [center]="mapCenter" [zoom]="15"
                            [options]="{clickableIcons: false, disableDefaultUI: true}"
                            (mapClick)="updatePlace($event)">
                    <map-marker *ngIf="markerPosition" [position]="markerPosition"
                                [options]="{draggable: false}"></map-marker>
                </google-map>
            </mat-expansion-panel>
            <div class="row end" style="margin-bottom: 8px">
                <mat-slide-toggle [(ngModel)]="isDefault" style="margin-bottom: 8px" i18n>Set as default</mat-slide-toggle>
            </div>
        </div>
        <div mat-dialog-actions class="row space-around">
            <button mat-button mat-dialog-close i18n>Cancel</button>
            <button mat-flat-button color="primary"
                    [disabled]="!name"
                    (click)="confirm()"
                    i18n>Save</button>
        </div>
    `,
    styles: [`
        .section-title {
            margin-top: 16px;
            margin-bottom: 8px;
        }

        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [ AsyncPipe,
        CommonModule, FormsModule, ReactiveFormsModule, GoogleMapsModule,
        MatDialogModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, MatExpansionModule, MatSlideToggle,
        LocationVisibilitySelectComponent,
    ]
})
export class EditLocationDialogComponent implements OnInit {

    name?: string

    mapCenter?: google.maps.LatLngLiteral
    markerPosition?: google.maps.LatLngLiteral

    markerAddress?: string
    checkedAddress?: string

    visibility: Visibility
    isDefault: boolean

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { location?: LocationResponse, defaultVisibility?: Visibility },
        private matDialogRef: MatDialogRef<EditLocationDialogComponent>,
        public googleMapsService: GoogleMapsService,
        private locationService: LocationService,
        private geoLocationService: GeoLocationService,
        private userService: UserService,
        private snackBar: MatSnackBar,
        private geocoder: MapGeocoder
    ) {
        this.name = data.location?.name
        this.visibility = data.location?.visibility || data.defaultVisibility || Visibility.PRIVATE
        this.isDefault = data.location?.isDefault || false
        this.markerAddress = data.location?.address
        if (this.markerAddress) {
            setTimeout(() => {
                this.checkAddress()
            }, 500)
        }
    }

    ngOnInit() {
        if (this.mapCenter) {
            return
        }
        this.geoLocationService.getGeoLocation().subscribe({
            next: geoPosition => {
                this.setGeoPosition(geoPosition)
            },
            error: error => {
                console.log(error)
                this.snackBar.open($localize`Failed to get your location. Please check your browser settings.`, "OK", {
                    duration: 5000,
                })
            }
        })
    }

    setGeoPosition(geoPosition: GeolocationPosition) {
        this.mapCenter = {
            lat: geoPosition.coords.latitude,
            lng: geoPosition.coords.longitude
        }

        const request: UpdateUserGeoLocationRequest = {
            coordinates: {
                latitude: geoPosition.coords.latitude,
                longitude: geoPosition.coords.longitude
            }
        }
        this.userService.updateUserGeoLocation(request).subscribe();
    }

    updatePlace(event: google.maps.MapMouseEvent) {
        if (!event.latLng) {
            return
        }

        this.markerPosition = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        }
        this.geocoder.geocode({location: event.latLng}).subscribe({
            next: (results) => {
                if (results.results.length > 0) {
                    this.markerAddress = results.results[0].formatted_address
                    this.checkedAddress = this.markerAddress
                }
            },
            error: () => {
                this.markerAddress = undefined
                this.checkedAddress = undefined
            }
        })
    }

    checkAddress() {
        if (!this.markerAddress) {
            return
        }
        this.geocoder.geocode({address: this.markerAddress}).subscribe({
            next: (results) => {
                if (results.results.length > 0) {
                    this.markerPosition = {
                        lat: results.results[0].geometry.location.lat(),
                        lng: results.results[0].geometry.location.lng()
                    }
                    this.mapCenter = this.markerPosition
                    this.checkedAddress = this.markerAddress
                }
            },
            error: (err) => {
                console.log(err)
                this.snackBar.open($localize`Failed to find the address. Please check the address and try again.`, "OK", {
                    duration: 5000,
                })
            }
        })
    }

    confirm() {
        if (!this.name) {
            return
        }

        const request: LocationRequest = {
            name: this.name,
            latitude: this.markerPosition?.lat,
            longitude: this.markerPosition?.lng,
            address: this.markerAddress,
            visibility: this.visibility,
            isDefault: this.isDefault,
        }
        if (this.data.location) {
            this.locationService.updateLocation(this.data.location.id, request).subscribe({
                next: () => {
                    const location: LocationResponse = {
                        id: this.data.location!.id,
                        ...this.data.location,
                        ...request
                    }
                    this.matDialogRef.close(location)
                },
                error: error => {
                    this.snackBar.open(error.error.message || $localize`There was an error fulfilling your request. Please try again later`, 'OK', {duration: 5000})
                }
            })
        } else {
            this.locationService.createLocation(request).subscribe({
                next: response => {
                    const location: LocationResponse = {
                        id: response.id,
                        ...request
                    }
                    this.matDialogRef.close(location)
                },
                error: error => {
                    this.snackBar.open(error.error.message || $localize`There was an error fulfilling your request. Please try again later`, 'OK', {duration: 5000})
                }
            })
        }
    }
}
