import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class DrawerService {
  public drawerState: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public open(): void {
    this.drawerState.next(true);
  }

  public close(): void {
    this.drawerState.next(false);
  }
}
