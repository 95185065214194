import {Component, Inject} from "@angular/core";
import {BoardGameResponse, GamePlayTemplateResponse} from "../model/responses";
import {AuthedUserService} from "../service/authed-user.service";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {GamePlayTemplateService} from "../service/api/game-play-template.service";
import {GamePlayScoreSheetRow, GamePlayStep} from "../model/game-play-template-data";
import {GamePlayTemplateRequest} from "../model/requests";
import {getLanguage, Language, languages} from "../model/languages";
import {MatButtonModule} from "@angular/material/button";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule, NgFor} from "@angular/common";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {SharedDialogsService} from "../service/shared-dialogs.service";

@Component({
    template: `
        <h1 mat-dialog-title *ngIf="data.update" i18n>Update Score Sheet for {{ data.game.name }}</h1>
        <h1 mat-dialog-title *ngIf="!data.update" i18n>New Score Sheet for {{ data.game.name }}</h1>
        <mat-dialog-content>
            <form class="column">
                <mat-form-field style="width: 100%; margin-top: 8px">
                    <mat-label i18n>Name</mat-label>
                    <input matInput [(ngModel)]="name" name="name" required
                           i18n-placeholder placeholder="Ultimate Score Sheet"
                    >
                    <mat-hint i18n>Name with which this score sheet will be listed</mat-hint>
                </mat-form-field>
                <mat-form-field style="width: 100%; margin-top: 8px">
                    <mat-label i18n>Language</mat-label>
                    <mat-select [(ngModel)]="language" name="language" required>
                        <mat-option *ngFor="let language of languages" [value]="language">{{ language?.name }}
                        </mat-option>
                    </mat-select>
                    <mat-hint i18n>Language in which this score sheet will be available</mat-hint>
                </mat-form-field>
                <!--<mat-form-field style="width: 100%; margin-top: 8px">
                  <mat-label i18n>Helper Description</mat-label>
                  <textarea matInput cdkTextareaAutosize [(ngModel)]="description" name="description"
                            i18n-placeholder placeholder="This helper has the best scoresheet ever."
                  ></textarea>
                  <mat-hint i18n>Further details about this helper (optional)</mat-hint>
                </mat-form-field>
                <span class="section-title" i18n>Setup</span>
                <mat-form-field style="width: 100%; margin-top: 8px">
                  <mat-label i18n>Introductory Text</mat-label>
                  <textarea matInput cdkTextareaAutosize [(ngModel)]="introductoryText" name="introductoryText"></textarea>
                  <mat-hint i18n>Initial text that will be displayed (optional)</mat-hint>
                </mat-form-field>
                <mat-form-field style="width: 100%; margin-top: 8px">
                  <mat-label i18n>Setup Steps</mat-label>
                  <textarea matInput cdkTextareaAutosize [(ngModel)]="setupStepsText" name="setupSteps"
                            i18n-placeholder placeholder="Place the board at the table\nSelect a color for each player\nPlace the score markers at 0\nShuffle the cards and place them close to the board"
                            rows="5"
                  ></textarea>
                  <mat-hint i18n>One row per step (optional)</mat-hint>
                </mat-form-field>
                <span class="section-title" i18n>Rounds</span>
                <mat-form-field style="width: 100%; margin-top: 8px">
                  <mat-label i18n>Round Count</mat-label>
                  <input matInput [(ngModel)]="roundCount" name="roundCount" placeholder="5">
                  <mat-hint i18n>Leave empty for games without rounds</mat-hint>
                </mat-form-field>
                <mat-form-field style="width: 100%; margin-top: 8px">
                  <mat-label i18n>Round Steps</mat-label>
                  <textarea matInput cdkTextareaAutosize [(ngModel)]="roundStepsText" name="roundSteps"
                            i18n-placeholder
                            placeholder="Earn Income\nPlayer Turns\nRemove Tiles\nCheck Bonus\nCheck End of Game"
                            rows="5"
                  ></textarea>
                  <mat-hint i18n>One row per step (optional)</mat-hint>
                </mat-form-field>-->
                <span class="section-title" i18n>Scoring</span>
                <mat-form-field style="width: 100%; margin-top: 8px">
                    <mat-label i18n>Score Sheet</mat-label>
                    <textarea matInput cdkTextareaAutosize [(ngModel)]="scoreSheetText" name="scoreSheet"
                              i18n-placeholder placeholder="Board\nTiles\nOrder Track Bonus\nExtra"
                              rows="5"
                    ></textarea>
                    <mat-hint i18n>One row per score sheet entry</mat-hint>
                </mat-form-field>
                <mat-slide-toggle [(ngModel)]="invert" [ngModelOptions]="{standalone: true}" i18n>Lower is winner</mat-slide-toggle>
            </form>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between">
            <button mat-button (click)="cancel()" i18n>Cancel</button>
            <button mat-flat-button color="primary" *ngIf="data.update" (click)="submit()" i18n>Update</button>
            <button mat-flat-button color="primary" *ngIf="!data.update" (click)="submit()" i18n>Create</button>
        </div>
    `,
    styles: [`
        .section-title {
            margin-top: 16px;
            font-size: 1.2em;
            font-weight: 700;
            color: var(--mat-app-text-color);
        }

        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [CommonModule, MatDialogModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, MatSlideToggle]
})
export class GameHelperDialogComponent {
    name?: string;
    language: Language = languages[0];
    description?: string;

    introductoryText?: string;
    setupStepsText?: string;
    roundStepsText?: string;
    scoreSheetText?: string;

    trackTurns: boolean = false;
    setupSteps: GamePlayStep[] = [];
    roundCount?: number;
    roundSteps: GamePlayStep[] = [];
    scoreSheet: GamePlayScoreSheetRow[] = [];

    invert: boolean = false;

    constructor(
        private gamePlayTemplateService: GamePlayTemplateService,
        @Inject(MAT_DIALOG_DATA) public data: {
            game: BoardGameResponse,
            baseTemplate?: GamePlayTemplateResponse,
            update: boolean
        },
        private dialogRef: MatDialogRef<GameHelperDialogComponent>,
        private sharedDialogsService: SharedDialogsService,
        private authedUserService: AuthedUserService,
    ) {
        this.authedUserService.assertAuthedUser()
        this.language = getLanguage(data.baseTemplate?.language || this.authedUserService.getAuthedUser()!.userData!.locale)
        this.name = data.baseTemplate?.name || $localize`Score Sheet`
        this.description = data.baseTemplate?.description
        this.introductoryText = data.baseTemplate?.data.introductoryText
        this.trackTurns = data.baseTemplate?.data.trackTurns || false
        this.setupSteps = data.baseTemplate?.data.setupSteps || []
        this.setupStepsText = this.setupSteps.map(step => step.title).join('\n')
        this.roundCount = data.baseTemplate?.data.roundCount
        this.roundSteps = data.baseTemplate?.data.roundSteps || []
        this.roundStepsText = this.roundSteps.map(step => step.title).join('\n')
        this.scoreSheet = data.baseTemplate?.data.scoreSheet || []
        this.scoreSheetText = this.scoreSheet.map(row => row.name).join('\n')
        this.invert = data.baseTemplate?.data.invertScoreSheet || false
    }

    cancel() {
        if (this.name || this.description || this.introductoryText || this.setupStepsText || this.roundStepsText || this.scoreSheetText) {
            this.sharedDialogsService.openCancelDialog().subscribe(result => {
                if (result) {
                    this.dialogRef.close()
                }
            })
        } else {
            this.dialogRef.close()
        }
    }

    submit() {
        this.setupSteps = this.setupStepsText?.split('\n')
            .filter(row => row.length > 0)
            .map(row => {
                const title = row.trim()
                const id = title.toLowerCase().replace(/ /g, '-')
                return {id: id, title: title} as GamePlayStep
            }) || []
        this.roundSteps = this.roundStepsText?.split('\n')
            .filter(row => row.length > 0)
            .map(row => {
                const title = row.trim()
                const id = title.toLowerCase().replace(/ /g, '-')
                return {id: id, title: title} as GamePlayStep
            }) || []
        this.scoreSheet = this.scoreSheetText?.split('\n')
            .filter(row => row.length > 0)
            .map(row => {
                const name = row.trim()
                return {name: name} as GamePlayScoreSheetRow
            }) || []
        const template: GamePlayTemplateRequest = {
            gameId: this.data.game.id,
            language: this.language.code,
            name: this.name!,
            description: this.description,
            data: {
                introductoryText: this.introductoryText,
                trackTurns: false,
                setupSteps: this.setupSteps,
                roundCount: this.roundCount || undefined,
                roundSteps: this.roundSteps,
                scoreSheet: this.scoreSheet,
                invertScoreSheet: this.invert,
            }
        }
        if (this.data.baseTemplate && this.data.update) {
            this.gamePlayTemplateService.updateGamePlayTemplate(this.data.baseTemplate.id, template).subscribe(() => {
                this.dialogRef.close(template)
            })
        } else {
            this.gamePlayTemplateService.createGamePlayTemplate(template).subscribe(t => {
                this.dialogRef.close(t)
            })
        }
    }

    protected readonly languages = languages;
}
