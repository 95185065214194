import {Injectable, Injector} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, Subject, tap} from "rxjs";
import {NotificationResponse, NotificationsResponse} from "../../model/responses";
import {environment} from "../../../environments/environment";
import {EventService} from "./event.service";

@Injectable()
export class NotificationService {

  private notificationsRetrieved = false
  private notifications: NotificationResponse[] = []
  private unreadNotificationCountSubject = new BehaviorSubject<number>(0)
  notificationSubject: Subject<NotificationResponse>

  constructor(
    private http: HttpClient,
    //private eventService: EventService
    private injector: Injector
  ) {
    this.notificationSubject = new Subject<NotificationResponse>()
    setTimeout(() => {
      let eventService = this.injector.get(EventService)
      eventService.subscribe('NOTIFICATION')
      this.notificationSubject = eventService.notificationSubject
      this.notificationSubject.subscribe(notification => {
        console.log('notification', notification)
        this.notifications.unshift(notification)
        this.unreadNotificationCountSubject.next(this.notifications.filter(notification => !notification.read).length)
      })
    }, 200)
  }

  getUnreadNotificationCount(): Observable<number> {
    if (!this.notificationsRetrieved) {
      setTimeout(() => {
          if (!this.notificationsRetrieved) {
            this.getNotifications().subscribe()
          }
        },
        1000
      )
    }
    return this.unreadNotificationCountSubject.asObservable()
  }

  getNotifications(): Observable<NotificationsResponse> {
    this.notificationsRetrieved = true
    return this.http.get<NotificationsResponse>(`${environment.apiUrl}/notifications`).pipe(
      tap(notifications => {
        this.notifications = [...notifications.list]
        this.unreadNotificationCountSubject.next(this.notifications.filter(notification => !notification.read).length)
      })
    );
  }

  getNotification(id: string): Observable<NotificationResponse> {
    return this.http.get<NotificationResponse>(`${environment.apiUrl}/notifications/${id}`)
  }

  setNofificationReadLocally(id: string) {
    this.notifications.find(notification => notification.id === id)!.read = true
    this.unreadNotificationCountSubject.next(this.notifications.filter(notification => !notification.read).length)
  }

  setNotificationRead(id: string, updateLocally: boolean = true): Observable<any> {
    if (updateLocally) {
      this.setNofificationReadLocally(id)
    }
    return this.http.post<any>(`${environment.apiUrl}/notifications/${id}/read`, {});
  }
}
